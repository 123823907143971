import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../lib/api";
import cogoToast from "cogo-toast";
import TablePagination from "../../component/TablePagination";
import { Form } from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import DeleteData from "../../component/DeleteData";
import { useNavBar } from "../../hooks/useNavBarContext";
import AddEvents from "./components/AddEvents";
import EditEvent from "./components/EditEvent";
import dayjs from "dayjs";

const Events = () => {
  const navigate = useNavigate();
  const [filterBtnActive, setFilterBtnActive] = useState("0");
  const [filter, setFilter] = useState("Approvals");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // edit modal open
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState({});

  // add modal open
  const [addOpen, setAddOpen] = useState(false);
  const [events, setEvents] = useState([]);

  // delete modal open
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const { community, setCommunity, search, setSearch } = useNavBar();
  // const filterButtons = ['Active', 'Inactive']

  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/get-events", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {
        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);
      }
    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      setEvents(data.data.data.result);
      setTotalPages(data.data.data.paginate?.totalPages);
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });

  //

  const { mutate: toggle } = useMutation({
    mutationFn: (data) => api.post("/web/toggle-hoa-status", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {
        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);
      }
    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      cogoToast.success(`${data.data.message}`);

      mutate({
        page,
        limit,
        filter,
      });
    },
  });

  //

  useEffect(() => {
    mutate({
      page,
      limit,
      filter,
      community,
      search
    });
  }, [filter, page, limit, editOpen, addOpen, deleteOpen, community, search]);

  return (
    <>
      <main className="content">
        {editOpen ? (
          <EditEvent
            show={editOpen}
            setShow={setEditOpen}
            editData={editData}
          />
        ) : (
          <></>
        )}

        {addOpen ? <AddEvents show={addOpen} setShow={setAddOpen} /> : <></>}

        {deleteOpen ? (
          <DeleteData
            show={deleteOpen}
            setShow={setDeleteOpen}
            data={deleteData}
            deleteType={"event"}
          />
        ) : (
          <></>
        )}

        <div>
          <div className="container px-44">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="card border-0 table-card">
                      <div className="card-body">
                        <div className="float-end">
                          <div className="d-flex">
                            <button
                              className="btn btn-main me-2"
                              onClick={() => {
                                setAddOpen(true);
                              }}
                            >
                              Add Events
                            </button>
                          </div>
                        </div>
                        <div className="clearfix mb-4 mt-2">
                          <div className="float-start">
                            <h4 className="thm-color">All Events</h4>
                          </div>
                          <div className="float-end"></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 align-middle common-table">
                            <thead>
                              <tr>
                                <th scope="col">S no.</th>
                                <th scope="col">Cover Image</th>

                                <th scope="col">Event Name</th>
                                <th scope="col">Start </th>
                                <th scope="col">End </th>
                                <th scope="col">Location</th>
                                <th scope="col">Description</th>

                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {events?.map((item, index) => {
                                return (
                                  <tr key={item._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item?.name}</td>
                                    <td>{item?.eventName}</td>
                                    <td>{dayjs(item?.startDate).format('DD-MM-YYYY hh:m:a')}</td>
                                    <td>{dayjs(item?.endDate).format('DD-MM-YYYY hh:mm:a')}</td>

                                    <td style={{ display: "flex" }}>
                                      <button
                                        className="btn btn-green me-3"
                                        onClick={() => {
                                          setEditOpen(true);
                                          setEditData(item);
                                        }}
                                      >
                                        <img
                                          src="/images/edit.svg"
                                          alt="edit"
                                          className="img-fluid"
                                        />
                                      </button>

                                      <button
                                        className="btn btn-red me-3"
                                        onClick={() => {
                                          setDeleteOpen(true);
                                          setDeleteData(item);
                                        }}
                                      >
                                        <img
                                          src="/images/delete.svg"
                                          alt="delete"
                                          className="img-fluid"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <TablePagination
                                totalPages={totalPages}
                                page={page}
                                setPage={setPage}
                              />
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Events;
