import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import api from '../../lib/api';
import cogoToast from 'cogo-toast';

const AboutUs = () => {
    const [aboutUs, setAboutUs] = useState('')


    const { data: getAboutUs, refetch } = useQuery({
        queryKey: ["about-us"],
        queryFn: () => api.get("web/about-us"),
        select: (res) => res.data.data[0]?.about,
    });




    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/add-about-us", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            cogoToast.success('Submitted')
            refetch()
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });




    const onSubtmit = (e) => {
        e.preventDefault()
        mutate({
            about: aboutUs
        })

    }




    useEffect(() => {
        if (getAboutUs) {
            setAboutUs(getAboutUs)
        }

    }, [getAboutUs])





    return (
        
            <main className="content">
                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container mt-5 px-44">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-slate mb-4">About Us</h4>
                            </div>
                            <div className="col-xl-6">
                                <div className="card border-0 table-card">
                                    <div className="card-body py-5 px-4">
                                        <form className="common-form" onSubmit={onSubtmit}>

                                            <div className="mb-4">
                                              
                                                <textarea rows={10} type="text" value={aboutUs} onChange={(e) => { setAboutUs(e.target.value) }} className="form-control" placeholder="Add About Us" />
                                            </div>
                                            <button type="submit" className="btn btn-main Shadow">Edit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>











       
    )
}

export default AboutUs