import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../../lib/api'

const AddTermsAndConditions = ({ show, setShow, refetch }) => {

    const handleClose = () => {

        setShow(false)

    }



    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm()





    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/add-terms-and-conditions", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 201) {
                cogoToast.success(`${data?.data?.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)
                refetch()
            }
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });








    const onSubmit = (data) => {
        const body = {
            title: data.title,
            description: data.description



        }


        mutate(body)
    }








    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>
                        {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
                        <div className="container px-44">
                            <div className="row">
                                <div className="col-md-12">
                                    
                                </div>
                                <div className="col-xl-12">
                                    <div className="card border-0  py-4 px-2">
                                    <h4 className="text-slate mb-3">Add Terms And Conditions</h4>
                                        <div className="card-body px-0">
                                            <form className="common-form" onSubmit={handleSubmit(onSubmit)} >
                                                <div className="mb-4">
                                                    <label className="form-label">Title</label>
                                                    <input type="text" className="form-control" placeholder="Enter Title" {...register("title", { required: "Title is required" })} />
                                                    {errors.title && <p style={{ color: 'red' }} role="alert">{errors.title.message}</p>}

                                                </div>
                                                <div className="mb-4">
                                                    <label className="form-label">Message</label>
                                                    <input type="text" className="form-control" placeholder="Enter Message" {...register("description", { required: "Description is required" })} />
                                                    {errors.description && <p style={{ color: 'red' }} role="alert">{errors.description.message}</p>}

                                                </div>
                                                <button type="submit" className="btn btn-main Shadow">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>
           
        </Modal>
    )
}

export default AddTermsAndConditions