import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import { Form, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import PhoneInput from 'react-phone-input-2'

const EditDocument = ({ show, setShow, editData }) => {






  const handleClose = () => {

    setShow(false)

  }



  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const { data: folders } = useQuery({
    queryKey: ["get-folders"],
    queryFn: () => api.get("common/get-folders"),
    select: (res) => res.data.data,
  });

  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/edit-upload-documents", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      if (data.data.status == 200) {
        cogoToast.success(`${data?.data?.message}`);
        setTimeout(() => {
          setShow(false)
        }, 1000)

      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });


  useEffect(() => {
    if (Object.values(editData)?.length > 0) {
      setValue("name", editData?.name)
      setValue("folderId", editData?.folderId)
    }


  }, [editData])

  console.log(editData)






  const onSubmit = (data) => {
    console.log(data)

    let fd = new FormData()
    fd.append('id', editData._id)

    fd.append('name', data.name)
    fd.append('folderId', data.folderId)
    if (data.document) {

      fd.append('document', data.document[0])
    }


    mutate(fd)
  }




  return (

    <Modal show={show} onHide={handleClose}>

      <Modal.Body>
        <main className="content">
          <div>
            {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
            <div className="container px-44">
              <div className="row">

                <div className="col-xl-12">
                  <div className="card border-0 py-4 px-2">
                    <h4 className="text-slate mb-3">Edit Document</h4>
                    <div className="card-body  px-0">
                      <form className="common-form" onSubmit={handleSubmit(onSubmit)} >

                        <div className="mb-4">
                          <label className="form-label">Name</label>
                          <input type="text" className="form-control" placeholder="Enter Document Name" {...register("name", { required: "Name is required" })} />
                          {errors.name && <p style={{ color: 'red' }} role="alert">{errors.name.message}</p>}

                        </div>


                        <div className="mb-4">
                          <label className="form-label">Folder</label>
                          <Form.Select aria-label="Default select example" {...register("folderId", { required: "folderId is required" })}>
                            <option>Open this select menu</option>
                            {
                              folders?.map((item) => {
                                return (
                                  <option value={item._id}>{item.name}</option>
                                )
                              })
                            }
                          </Form.Select>
                          {errors.folderId && <p style={{ color: 'red' }} role="alert">{errors.folderId.message}</p>}

                        </div>

                        <div className="mb-4">
                          <label className="form-label">Upload Documents</label>
                          <label for="file-input" id="custom-button">
                            <img src="/images/upload.svg" alt="upload" className="img-fluid" />
                          </label>
                          <span id="file-name"></span>
                          <input type="file" id="file-input" {...register("document")} />

                        </div>



                        <button type="submit" className="btn btn-main Shadow">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>

    </Modal>


  )
}

export default EditDocument