export const menus = [
  { name: "Dashboard", path: "/", image: "1" },
  { name: "Member Mgmt.", path: "/community-member", image: "2" },
  {
    name: "Community Announcements",
    path: "/community-announcements",
    image: "3",
  },
  {
    name: "Events",
    path: "/events",
    image: "3",
  },
  {
    name: "Help Section",
    path: "/",
    image: "6",
    dropdown: true,
    subMenu: [
      // { name: "Tickets", path: "/get-tickets", image: "12" },
      { name: "Emergency Contacts", path: "/emergency-contacts", image: "12" },
      { name: "Tickets", path: "/tickets", image: "12" },

    ],
  },
  { name: "Documents", path: "/documents", image: "12" },
  {
    name: "Sell Categories",
    path: "/sell-categories",
    image: "10",
  },
  { name: "Facility Bookings", path: "/facility-bookings", image: "11" },
  {
    name: "Facilities",
    path: "/facilities",
    image: "11",
  },
  {
    name: "Homeowners Association",
    path: "/homeowners-association",
    image: "12",
  },
  { name: "Logout", path: "/", image: "13" },
];
