import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-input-2/lib/style.css'

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomeLayout from "./component/Layout";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import CommunityMemberManagement from "./pages/CommunityMemberManagement/CommunityMemberManagement";
import AddUsers from "./pages/CommunityMemberManagement/components/AddUsers";
import SideBarTemp from "./pages/SideBarTemp";
import AboutUs from "./pages/staticPages/AboutUs";
import PrivacyPolicy from "./pages/staticPages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/staticPages/TermsAndConditions.js/TermsAndConditions";
import SupportTickets from "./pages/HelpSection/SupportTickets";
import GlobalAnnouncements from "./pages/CommunityAnnouncements/GlobalAnnouncements";
import CommunityManagement from "./pages/CommunityManagement/CommunityManagment";
import EmergencyContacts from "./pages/EmergencyContacts/EmergencyContacts";
import Documents from "./pages/DocumentManagement/Documents";
import Events from "./pages/Events/Events";
import HelpDesks from "./pages/HelpSection/HelpDesks";
import SellCategories from "./pages/SellCategories/SellCategories";
import Facilities from "./pages/Facilities/Facilities";
import HomeOwnersAssociation from "./pages/HomeOwnersAssociation/HomeOwnersAssociation";
import FacilityBookings from "./pages/FacilityBookings";

function App() {
  //   useEffect(() => {
  //     window.open("/");
  //   });

  return (
    <>
      <Routes>
        <Route element={<Register />} path="/register" />
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ResetPassword />} path="/reset-password" />


        <Route element={<HomeLayout />}>
          {/* <Route element={<SideBarTemp />} path="/" /> */}

          <Route element={<Dashboard />} path="/" />
          <Route element={<CommunityMemberManagement />} path="/community-member" />
          <Route element={<CommunityManagement />} path="/community-management" />
          <Route element={<AddUsers />} path="/add-users" />
          <Route element={<AboutUs />} path="/aboutUs" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
          <Route element={<SupportTickets />} path="/get-tickets" />
          <Route element={<EmergencyContacts />} path="/emergency-contacts" />
          <Route element={<Documents />} path="/documents" />
          <Route element={<Events />} path="/events" />
          <Route element={<HelpDesks />} path="/tickets" />
          <Route element={<FacilityBookings />} path="/facility-bookings" />


          <Route element={<SellCategories />} path="/sell-categories" />
          <Route element={<Facilities />} path="/facilities" />
          <Route element={<HomeOwnersAssociation />} path="/homeowners-association" />
          <Route element={<GlobalAnnouncements />} path="/community-announcements" />






        </Route>
      </Routes>
    </>
  );
}

export default App;
