import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../lib/api'
import cogoToast from 'cogo-toast'
// import EditUsers from './components/EditUsers'
import TablePagination from '../../component/TablePagination'
// import ViewUsers from './components/ViewUsers'
import { useNavBar } from '../../hooks/useNavBarContext'
import { BsChevronRight } from "react-icons/bs";
import { Form } from 'react-bootstrap'
import FormCheckLabel from 'react-bootstrap/FormCheckLabel'
import DeleteData from '../../component/DeleteData'
import EditEmergencyContacts from './components/EditEmergencyContacts'
import AddEmergencyContacts from './components/AddEmergencyContacts'

const EmergencyContacts = () => {
    const navigate = useNavigate()
    const [filterBtnActive, setFilterBtnActive] = useState('0')
    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [addOpen, setAddOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [editData, setEditData] = useState({})
    // view modal open
    const [viewOpen, setViewOpen] = useState(false)
    const [viewData, setViewData] = useState({})
    const [data, setData] = useState([])
    const { community, setCommunity, search, setSearch } = useNavBar()

    // delete modal open
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteData, setDeleteData] = useState({})

    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/get-emergency-contacts", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            setData(data.data.data.result)
            setTotalPages(data.data.data.paginate?.totalPages)
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });



    // 

    const { mutate: toggle } = useMutation({
        mutationFn: (data) => api.post("/web/toggle-user-status", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            cogoToast.success(`${data.data.message}`)

            mutate({
                page,
                limit,
                community
            })
        },

    });

    // 






    useEffect(() => {
        mutate({
            page,
            limit,
            community,
            search


        })


    }, [page, limit, editOpen, deleteOpen, community, search,addOpen])












    return (
        <>
            <main className="content">
                {
                    editOpen ? <EditEmergencyContacts show={editOpen} setShow={setEditOpen} editData={editData} /> : <></>

                }


                {
                    addOpen ? <AddEmergencyContacts show={addOpen} setShow={setAddOpen} /> : <></>

                }


                {
                    deleteOpen ? <DeleteData show={deleteOpen} setShow={setDeleteOpen} data={deleteData} deleteType={"contacts"} /> : <></>

                }



                <div>

                    <div className="container px-44">
                        <div className="row">


                            <div className="col-md-12">


                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div className="card border-0 table-card">
                                            <div className="card-body">
                                                <div className="float-end">
                                                    <div className="d-flex">
                                                        <button className="btn btn-main me-2" onClick={() => {
                                                            setAddOpen(true)
                                                        }} >Add Emergency Contact</button>

                                                    </div>
                                                </div>
                                                <div className="clearfix mb-4 mt-2">
                                                    <div className="float-start">
                                                        <h4 className="thm-color">Emergency Contacts</h4>
                                                    </div>
                                                    <div className="float-end">
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-striped mb-0 align-middle common-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S no.</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Emergency Type</th>
                                                                <th scope="col">contact</th>

                                                                <th scope="col">Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.map((item, index) => {
                                                                    return (

                                                                        <tr key={item._id} >
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{item?.name}</td>
                                                                            <td>{item?.emergencyType}</td>
                                                                            <td>{item?.contact}</td>

                                                                            <td style={{ display: 'flex' }}>
                                                                                <button className="btn btn-green me-3" onClick={() => {
                                                                                    setEditOpen(true)
                                                                                    setEditData(item)

                                                                                }} ><img src="/images/edit.svg" alt="edit" className="img-fluid" /></button>

                                                                                <button className="btn btn-red me-3" onClick={() => {
                                                                                    setDeleteOpen(true)
                                                                                    setDeleteData(item)
                                                                                }} ><img src="/images/delete.svg" alt="delete" className="img-fluid" /></button>

                                                                            </td>

                                                                        </tr>


                                                                    )


                                                                })

                                                            }

                                                        </tbody>
                                                        <tfoot>
                                                            <TablePagination totalPages={totalPages} page={page} setPage={setPage} />
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    )
}

export default EmergencyContacts