import { useMutation, useQuery } from '@tanstack/react-query';
import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import api from '../../../lib/api';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { InputGroup } from "react-bootstrap";
import { IoEye } from "react-icons/io5";

import { IoEyeOff } from "react-icons/io5";
const AddUsers = () => {
    const [code, setCode] = useState('')
    const [phone, setPhone] = useState('')
    const [flag, setFlag] = useState('')
    const navigate = useNavigate()
    const { user, logout } = useAuth()
    const [showPass, setShowPass] = React.useState(false)

    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState: { errors },
    } = useForm()


    const { data: getCommunities } = useQuery({
        queryKey: ["get-community"],
        queryFn: () => api.get("common/get-community"),
        select: (res) => res.data.data.result,
    });


    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/register", data),

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 201) {
                cogoToast.success(`${data.data.message}`);
                reset()
                setPhone(code)
            }
        },

    });


    useEffect(() => {
        setValue('community', user.community)
    }, [user])






    const onSubmit = (data) => {
        if (!phone) {
            cogoToast.error('Mobile number is not entered')
            return
        }
        let dialCode = code
        let withoutCodeNumber = phone.slice(code.length)

        const body = {
            name: data.name,
            email: data.email,
            password: data.password,
            phone: withoutCodeNumber,
            dialCode: `+${dialCode}`,
            flag: flag,
            address: {
                address_line_1: data.address1,
                address_line_2: data.address2,
                city: data.city,
                state: data.state,
                zip: data.zipCode,


            },
            community: data.community



        }

        mutate(body)
    }



    return (
        <>

            <main className="content">
                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container mt-5 px-44">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-slate mb-4">Add User</h4>
                            </div>
                            <div className="col-md-12">
                                <div className="card border-0 table-card">
                                    <div className="card-body py-5 px-4">

                                        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Name</label>
                                                        <input type="name" className="form-control" placeholder="Enter Name" {...register("name", { required: "Name is required" })} />
                                                        {errors.name && <p style={{ color: 'red' }} role="alert">{errors.name.message}</p>}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Email</label>
                                                        <input type="email" className="form-control" placeholder="Enter Email" {...register("email", { required: "Email is required" })} />
                                                        {errors.email && <p style={{ color: 'red' }} role="alert">{errors.email.message}</p>}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label htmlFor="exampleInputPassword" className="form-label fw-bolder text-label">Password</label>
                                                        <InputGroup>
                                                            {/* <Form.Control aria-label="Dollar amount (with dot and two decimal places)" /> */}
                                                            <input type={showPass ? "text" : "password"} className="form-control" placeholder="Enter Password" id="exampleInputPassword" {...register("password", {
                                                                required: "Password is required", validate: (value) => {
                                                                    if (
                                                                        value.length < 8 ||
                                                                        !/[A-Z]/.test(value) ||
                                                                        !/[a-z]/.test(value) ||
                                                                        !/[!@#$%^&*()\-=_+{};':",.<>/?[\]\\|]/.test(value)
                                                                    ) {
                                                                        return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                                                    }

                                                                }
                                                            })} />

                                                            <InputGroup.Text onClick={() => {
                                                                setShowPass(!showPass)
                                                            }} >{showPass ? <IoEye /> : <IoEyeOff />} </InputGroup.Text>
                                                        </InputGroup>
                                                        {errors.password && <p style={{ color: 'red' }} role="alert">{errors.password.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Mobile Number</label>
                                                        {/* <input type="text" className="form-control" placeholder="Enter Mobile Number" {...register("phone", {
                                                            required: "Mobile is required", maxLength: {
                                                                value: 10,
                                                                message: "Mobile number should be 10 digit.",
                                                            }
                                                        })} /> */}
                                                        <PhoneInput
                                                            country={'in'}
                                                            // value={this.state.phone}
                                                            onChange={(value, country) => {
                                                                setCode(country.dialCode)

                                                                setPhone(value)
                                                                setFlag(country.name)
                                                            }}
                                                            enableSearch={true}
                                                        />




                                                        {errors.phone && <p style={{ color: 'red' }} role="alert">{errors.phone.message}</p>}

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Address</label>
                                                        <input type="text" className="form-control mb-4" placeholder="Enter Address 1" {...register("address1", { required: "Address Line 1 is required" })} />

                                                        {errors.address1 && <p style={{ color: 'red' }} role="alert">{errors.address1.message}</p>}

                                                        <input type="text" className="form-control mb-4" placeholder="Enter Address 2" {...register("address2", { required: "Address Line 2 is required" })} />

                                                        {errors.address2 && <p style={{ color: 'red' }} role="alert">{errors.address2.message}</p>}


                                                        <input type="text" className="form-control mb-4" placeholder="State" {...register("state", { required: "state is required" })} />
                                                        {errors.state && <p style={{ color: 'red' }} role="alert">{errors.state.message}</p>}

                                                        <input type="text" className="form-control mb-4" placeholder="City" {...register("city", { required: "city is required" })} />
                                                        {errors.city && <p style={{ color: 'red' }} role="alert">{errors.city.message}</p>}

                                                        <input type="text" className="form-control mb-4" placeholder="Zip Code" {...register("zipCode", { required: "city is required" })} />
                                                        {errors.zipCode && <p style={{ color: 'red' }} role="alert">{errors.zipCode.message}</p>}

                                                        <select {...register("community", { required: "community is required" })} disabled className='form-control'>

                                                            {
                                                                getCommunities?.filter((fi) => fi._id == user.community)?.map((item) => {
                                                                    return (


                                                                        <option value={item._id} selected >{item.name} </option>

                                                                    )

                                                                })
                                                            }



                                                        </select>

                                                        {errors.community && <p style={{ color: 'red' }} role="alert">{errors.community.message}</p>}
                                                    </div>
                                                </div>





                                            </div>
                                            <button type="submit" className="btn btn-main Shadow">Submit</button>
                                        </form>





                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </main>



        </>
    )
}

export default AddUsers