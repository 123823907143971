import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { useForm } from "react-hook-form";
import api from "../../../lib/api";
import PhoneInput from "react-phone-input-2";
import { useNavBar } from "../../../hooks/useNavBarContext";

const AddEvents = ({ show, setShow, refetch }) => {
  const { community, setCommunity } = useNavBar();

  const handleClose = () => {
    setShow(false);
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();


  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/add-events", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {
        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);
      }
    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      if (data.data.status == 201) {
        cogoToast.success(`${data?.data?.message}`);
        setTimeout(() => {
          setShow(false);
        }, 1000);
      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });







  const onSubmit = (data) => {
    console.log(data);
    let fd = new FormData();
    fd.append("community", community);

    fd.append("eventName", data.eventName);
    fd.append("description", data.description);
    fd.append("location", data.location);
    fd.append("coverImage", data.coverImage[0]);

    fd.append("startDate", data.startDate);
    fd.append("endDate", data.endDate);
    fd.append("startTime", data.startDate);
    fd.append("endTime", data.endDate);

    mutate(fd);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <main className="content">
          <div>
            {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
            <div className="container px-44">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card border-0 py-4 px-2">
                    <h4 className="text-slate mb-3">Add Events</h4>
                    <div className="card-body  px-0">
                      <form
                        className="common-form"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="mb-4">
                          <label className="form-label">Event Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Event Name"
                            {...register("eventName", {
                              required: "Event Name is required",
                            })}
                          />
                          {errors.eventName && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.eventName.message}
                            </p>
                          )}
                        </div>

                        <div className="mb-4">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="Start Date"
                            {...register("startDate", {
                              required: "Start Date is required",
                            })}
                          />
                          {errors.startDate && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.startDate.message}
                            </p>
                          )}
                        </div>

                        <div className="mb-4">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="End Date"
                            {...register("endDate", {
                              required: "End Date is required",
                            })}
                          />
                          {errors.endDate && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.endDate.message}
                            </p>
                          )}
                        </div>

                        {/* <div className="mb-4">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="Start Time"
                            {...register("startTime", {
                              required: "Start Time is required",
                            })}
                          />

                          {errors.startTime && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.startTime.message}
                            </p>
                          )}
                        </div>

                        <div className="mb-4">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="End Time"
                            {...register("endTime", {
                              required: "End Time is required",
                            })}
                          />
                          {errors.endTime && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.endTime.message}
                            </p>
                          )}
                        </div> */}

                        <div className="mb-4">
                          <label className="form-label">Location</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                            {...register("location", {
                              required: "Location is required",
                            })}
                          />
                          {errors.location && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.location.message}
                            </p>
                          )}
                        </div>

                        <div className="mb-4">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Description"
                            {...register("description", {
                              required: "Description is required",
                            })}
                          />
                          {errors.description && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.description.message}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Cover Photo</label>

                          <Form.Control
                            type="file"
                            {...register("coverImage", {
                              required: "cover Image is required",
                            })}
                          />
                          {errors.coverImage && (
                            <p style={{ color: "red" }} role="alert">
                              {errors.coverImage.message}
                            </p>
                          )}
                        </div>
                        <button type="submit" className="btn btn-main Shadow">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  );
};

export default AddEvents;
