import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import { useNavBar } from '../../../hooks/useNavBarContext'

const AddHomeOwnersAssociation = ({ show, setShow }) => {

    const handleClose = () => {

        setShow(false)

    }

    const { community, setCommunity, search, setSearch } = useNavBar();


    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm()





    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/add-houseowners-association", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 201) {
                cogoToast.success(`${data?.data?.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)
            }
        },

    });








    const onSubmit = (data) => {

        let fd = new FormData()
        fd.append('name', data.name)
        fd.append('contact', data.contact)
        fd.append('community', community)
        

            fd.append('image', data.image[0])
        


        mutate(fd)
    }








    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>

                        <div className="container px-44">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card border-0 py-4 px-2">
                                        <h4 className="text-slate mb-3">Add HomeOwnersAssociation</h4>
                                        <div className="card-body px-0">
                                            <form className="common-form" onSubmit={handleSubmit(onSubmit)} >
                                                <div className="mb-4">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Name" {...register("name", { required: "Name is required" })} />
                                                    {errors.name && <p style={{ color: 'red' }} role="alert">{errors.name.message}</p>}

                                                </div>


                                                <div className="mb-4">
                                                    <label className="form-label">Contact</label>
                                                    <input type="text" className="form-control"  {...register("contact")} />
                                                    {errors.contact && <p style={{ color: 'red' }} role="alert">{errors.contact.message}</p>}

                                                </div>

                                                <div className="mb-4">
                                                    <label className="form-label">Image</label>
                                                    <input type="file" className="form-control"  {...register("image")} />
                                                    {errors.image && <p style={{ color: 'red' }} role="alert">{errors.image.message}</p>}

                                                </div>



                                                <button type="submit" className="btn btn-main Shadow">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>




        </Modal>
    )
}

export default AddHomeOwnersAssociation