import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "./useCookies";
import { useEffect } from "react";
import { useAuth } from "./useAuth";

const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
    const [cookie, setCookie] = useCookies("neighbour_hub_community_token", null);
    const { user } = useAuth()
    const [community, setCommunity] = useState(user?.community)
    const [search, setSearch] = useState('')

    const navigate = useNavigate();
    useEffect(() => {
        setCommunity(user?.community)


    }, [user])

    const value = useMemo(
        () => ({
            community, setCommunity, search, setSearch
        }),
        [community, search]
    );

    return <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>;
};

export const useNavBar = () => {
    return useContext(NavBarContext);
};
