import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import { useNavBar } from '../../../hooks/useNavBarContext'

const AddFacilities = ({ show, setShow }) => {

    const handleClose = () => {

        setShow(false)

    }

    const { community } = useNavBar();


    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm()





    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/add-facility", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 201) {
                cogoToast.success(`${data?.data?.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)
            }
        },

    });








    const onSubmit = (data) => {

        let body = {
            facilityName: data.facilityName,
            community: community

        }

        mutate(body)
    }








    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>

                        <div className="container px-44">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card border-0 py-4 px-2">
                                        <h4 className="text-slate mb-3">Add Facility</h4>
                                        <div className="card-body px-0">
                                            <form className="common-form" onSubmit={handleSubmit(onSubmit)} >

                                                <div className="mb-4">
                                                    <label className="form-label">Facility Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Facility Name" {...register("facilityName", { required: "facilityName is required" })} />
                                                    {errors.facilityName && <p style={{ color: 'red' }} role="alert">{errors.facilityName.message}</p>}

                                                </div>





                                                <button type="submit" className="btn btn-main Shadow">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>




        </Modal>
    )
}

export default AddFacilities