import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import api from '../../lib/api';
import cogoToast from 'cogo-toast';
import TablePagination from '../../component/TablePagination';
import { Form } from 'react-bootstrap';
import { useNavBar } from '../../hooks/useNavBarContext';

const SupportTickets = () => {

    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const { community, setCommunity, search, setSearch } = useNavBar()


    const { data: data, refetch
    } = useQuery({
        queryKey: ["get-tickets", page, limit],
        queryFn: () => api.get(`web/get-tickets?page=${page}&limit=${limit}&community=${community}`),
        select: (res) => res.data.data,
    });

    const { mutate: toggle } = useMutation({
        mutationFn: (data) => api.post("/web/toggle-tickets-status", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            if (data.data.status == 200) {
                cogoToast.success(`${data.data.message}`);
                refetch()

            }
        },

    });

    return (
        <>
            <main className="content">



                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container px-44">
                        <div className="row">


                            <div className="col-md-12">


                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div className="card border-0 table-card">
                                            <div className="card-body">
                                                <div className="clearfix mb-4 mt-2">
                                                    <div className="float-start">
                                                        <h4 className="thm-color">Support Tickets</h4>
                                                    </div>
                                                    <div className="float-end">
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-striped mb-0 align-middle common-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S no.</th>
                                                                <th scope="col">UserInfo</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col">Description</th>
                                                                <th>Action</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.result?.map((item, index) => {
                                                                    return (

                                                                        <tr key={item._id} >
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{item?.userInfo}</td>
                                                                            <td>{item?.subject}</td>
                                                                            <td>{item?.description}</td>

                                                                            <td  >


                                                                                <Form.Check
                                                                                    onChange={(e) => {
                                                                                        toggle({
                                                                                            id: item._id,
                                                                                            status: !item.isActive

                                                                                        })
                                                                                    }}
                                                                                    checked={item.isActive}
                                                                                    type="switch"
                                                                                    id="custom-switch"
                                                                                    label={`${item.isActive ? 'InActive' : 'Active'}`}
                                                                                />





                                                                            </td>
                                                                            {/* <td>
                                      </td> */}
                                                                        </tr>


                                                                    )


                                                                })

                                                            }

                                                        </tbody>
                                                        <tfoot>
                                                            <TablePagination totalPages={data?.paginate?.totalPages} page={page} setPage={setPage} />

                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>




        </>
    )
}

export default SupportTickets