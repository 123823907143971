import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'

const EditFacilities = ({ show, setShow, editData, refetch }) => {

    const handleClose = () => {

        setShow(false)

    }



    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm()





    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/edit-facility", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            if (data.data.status == 200) {
                cogoToast.success(`${data.data.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)
                refetch()

            }
        },

    });



    useEffect(() => {
        if (Object.values(editData)?.length > 0) {
            setValue("facilityName", editData?.facilityName)

        }


    }, [editData])




    const onSubmit = (data) => {
        const body = {
            facilityName: data.facilityName,
            id: editData._id
        }

        mutate(body)
    }








    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>

                        <div className="container px-44">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card border-0 py-4 px-2">
                                        <h4 className="text-slate mb-3">Edit Facilities</h4>
                                        <div className="card-body px-0">
                                            <form className="common-form" onSubmit={handleSubmit(onSubmit)} >
                                                <div className="mb-4">
                                                    <label className="form-label">facilityName</label>
                                                    <input type="text" className="form-control" placeholder="Enter facilityName" {...register("facilityName", { required: "facilityName is required" })} />
                                                    {errors.facilityName && <p style={{ color: 'red' }} role="alert">{errors.facilityName.message}</p>}

                                                </div>



                                                <button type="submit" className="btn btn-main Shadow">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>

        </Modal>
    )
}

export default EditFacilities