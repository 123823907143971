import React, { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import api from '../lib/api'
import { useNavBar } from '../hooks/useNavBarContext'
import AdminAssignModal from './AdminAssignModal'

const NavBar = () => {
    const { user, logout } = useAuth()
    const { community, setCommunity, setSearch, search } = useNavBar()
    const [adminAssignModal, setAdminAssignModal] = useState(false)

    const location = useLocation()
    const { data: getCommunities } = useQuery({
        queryKey: ["get-community"],
        queryFn: () => api.get("common/get-community"),
        select: (res) => res.data.data.result,
    });


    console.log('community value', community)



    return (
        <>

            <nav className="navbar navbar-expand-md bg-white sticky-top">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <form className="d-flex" role="search">
                        <div className="ref-image">
                            <div className="input-group br">
                                <input type="text" value={search} className="form-control" placeholder="Search..." onChange={(e) => {
                                    setSearch(e.target.value)
                                }} />
                                <span className="input-group-text bg-white  border-0"><img src="/images/search.svg" alt="search" /></span>
                            </div>
                        </div>
                    </form>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto btn btn-primary p-2" onClick={() => {
                            setAdminAssignModal(true)
                        }}>

                            {user.communityName} Admin

                        </ul>
                    </div>
                </div>
            </nav>

            <AdminAssignModal show={adminAssignModal} setShow={setAdminAssignModal} />

        </>
    )
}

export default NavBar