import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../lib/api'
import cogoToast from 'cogo-toast'
import EditUsers from './components/EditUsers'
import TablePagination from '../../component/TablePagination'
import ViewUsers from './components/ViewUsers'
import { useNavBar } from '../../hooks/useNavBarContext'
import { BsChevronRight } from "react-icons/bs";
import { Form } from 'react-bootstrap'
import FormCheckLabel from 'react-bootstrap/FormCheckLabel'
import DeleteData from '../../component/DeleteData'

const CommunityMemberManagement = () => {
  const navigate = useNavigate()
  const [filterBtnActive, setFilterBtnActive] = useState('0')
  const [filter, setFilter] = useState('Approvals')
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [editOpen, setEditOpen] = useState(false)
  const [editData, setEditData] = useState({})
  // view modal open
  const [viewOpen, setViewOpen] = useState(false)
  const [viewData, setViewData] = useState({})
  const [users, setUsers] = useState([])
  const { community, setCommunity, search, setSearch } = useNavBar()
  const filterButtons = ['Approvals', 'Active', 'Inactive']

  // delete modal open
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteData, setDeleteData] = useState({})
  const inputRef = useRef(null)
  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/get-users", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      setUsers(data.data.data.result)
      setTotalPages(data.data.data.paginate?.totalPages)
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });



  // 

  const { mutate: toggle } = useMutation({
    mutationFn: (data) => api.post("/web/toggle-user-status", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      cogoToast.success(`${data.data.message}`)

      mutate({
        page,
        limit,
        filter,
        community
      })
    },

  });

  // 


  const { mutate: uploadCsv } = useMutation({
    mutationFn: (data) => api.post("/web/upload-user-csv", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      cogoToast.success(`${data.data.message}`)

      mutate({
        page,
        limit,
        filter,
        community
      })
    },

  });




  const { mutate: toggleUserApprovalStatus } = useMutation({
    mutationFn: (data) => api.post("/web/toggle-user-approval-status", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      cogoToast.success(`${data.data.message}`)

      mutate({
        page,
        limit,
        filter,
        community
      })
    },

  });



  useEffect(() => {
    mutate({
      page,
      limit,
      filter,
      community,
      search


    })


  }, [filter, page, limit, editOpen, deleteOpen, community, search])












  return (
    <>
      <main className="content">
        {
          editOpen ? <EditUsers show={editOpen} setShow={setEditOpen} editData={editData} /> : <></>

        }


        {
          viewOpen ? <ViewUsers show={viewOpen} setShow={setViewOpen} viewData={viewData} /> : <></>

        }


        {
          deleteOpen ? <DeleteData show={deleteOpen} setShow={setDeleteOpen} data={deleteData} deleteType={"user"} /> : <></>

        }



        <div>
          <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
            <i className="ri-menu-line ri-xl"></i>
          </a>
          <div className="container px-44">
            <div className="row">


              <div className="col-md-12">
                <div className="clearfix mb-4 mt-2">
                  <div className="float-start">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">



                      {
                        filterButtons?.map((item, index) => {
                          return (

                            <li className="nav-item" role="presentation" key={index.toString()} >
                              <button className={filterBtnActive == index ? "nav-link active" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => {
                                setFilterBtnActive(index)
                                setFilter(item)
                                setPage(1)

                              }} >{item}</button>
                            </li>

                          )

                        })

                      }



                    </ul>
                  </div>

                </div>

                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                    <div className="card border-0 table-card">
                      <div className="card-body">
                        <input type="file" hidden ref={inputRef} onChange={(e) => {
                          const fd = new FormData()
                          fd.append('community', community)

                          fd.append('document', e.target.files[0])
                          uploadCsv(fd)
                        }} />
                        <div className="float-end">
                          <div className="d-flex">
                            <button className="btn btn-main me-2" onClick={() => {
                              // uploadCsv
                              console.dir(inputRef.current.onChange)
                              inputRef.current.click()

                            }} >Import Csv</button>

                          </div>
                        </div>

                        <div className="float-end">
                          <div className="d-flex">
                            <button className="btn btn-main me-2" onClick={() => {
                              navigate('/add-users')
                            }} >Add User</button>

                          </div>
                        </div>

                        <div className="clearfix mb-4 mt-2">
                          <div className="float-start">
                            <h4 className="thm-color">All Users</h4>
                          </div>
                          <div className="float-end">
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 align-middle common-table">
                            <thead>
                              <tr>
                                <th scope="col">S no.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Community Name</th>

                                <th scope="col">Action</th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                users?.map((item, index) => {
                                  return (

                                    <tr key={item._id} >
                                      <th scope="row">{index + 1}</th>
                                      <td>{item?.name}</td>
                                      <td>{item?.email}</td>
                                      <td>{item?.phone}</td>
                                      <td>{item?.communityName}</td>

                                      <td style={{ display: 'flex' }}>
                                        <button className="btn btn-green me-3" onClick={() => {
                                          setEditOpen(true)
                                          setEditData(item)

                                        }} ><img src="/images/edit.svg" alt="edit" className="img-fluid" /></button>
                                        <button className="btn btn-green me-3" onClick={() => {
                                          setViewOpen(true)
                                          setViewData(item)

                                        }} ><img src="/images/eye.svg" alt="eye" className="img-fluid" /></button>
                                        <button className="btn btn-red me-3" onClick={() => {
                                          setDeleteOpen(true)
                                          setDeleteData(item)
                                        }} ><img src="/images/delete.svg" alt="delete" className="img-fluid" /></button>

                                        <Form.Check
                                          onChange={(e) => {
                                            toggle({
                                              userId: item._id,
                                              status: !item.isActive

                                            })
                                          }}
                                          checked={item.isActive}
                                          type="switch"
                                          className="me-3"
                                          id={`custom-switch${item._id}`}
                                          label={`${item.isActive ? 'InActive' : 'Active'}`}

                                        // label="Check this switch"
                                        />



                                        <Form.Check
                                          onChange={(e) => {
                                            toggleUserApprovalStatus({
                                              userId: item._id,
                                              status: !item.isApproved

                                            })
                                          }}
                                          checked={item.isApproved}
                                          type="switch"
                                          id={`custom-switch${item._id}1`}
                                          label={`${item.isApproved ? 'Disapprove' : 'Approve'}`}

                                        // label="Check this switch"
                                        />


                                      </td>

                                    </tr>


                                  )


                                })

                              }

                            </tbody>
                            <tfoot>
                              <TablePagination totalPages={totalPages} page={page} setPage={setPage} />
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

    </>
  )
}

export default CommunityMemberManagement